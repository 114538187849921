// Generated by Framer (fcda68f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CiA8AIOeS"];

const serializationHash = "framer-Gmgo7"

const variantClassNames = {CiA8AIOeS: "framer-v-1satbo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.45, ease: [0.44, 0, 0.17, 0.99], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({buttonLink, height, id, width, ...props}) => { return {...props, eBwpRTt01: buttonLink ?? props.eBwpRTt01} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;buttonLink?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, eBwpRTt01, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CiA8AIOeS", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseLeave1e6jsbq = activeVariantCallback(async (...args) => {
setVariant("CiA8AIOeS")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Gmgo7", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={eBwpRTt01}><motion.a {...restProps} className={`${cx("framer-1satbo", className)} framer-46dw56`} data-border data-framer-name={"Button Active"} data-highlight data-reset={"button"} layoutDependency={layoutDependency} layoutId={"CiA8AIOeS"} onMouseLeave={onMouseLeave1e6jsbq} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(48, 48, 48, 0.25)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(232, 255, 173)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)", ...style}}><motion.div className={"framer-11lxfvo"} layoutDependency={layoutDependency} layoutId={"McEzj0_CR"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1RBU0EgT3JiaXRlciBEZWNrIEJvbGQ=", "--framer-font-family": "\"TASA Orbiter Deck Bold\", \"TASA Orbiter Deck Bold Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-text-alignment": "center"}}>JOIN KRONOS.CLUB</motion.p></React.Fragment>} className={"framer-1bxyp62"} fonts={["CUSTOM;TASA Orbiter Deck Bold"]} layoutDependency={layoutDependency} layoutId={"ROkJJIqaq"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Gmgo7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Gmgo7 .framer-46dw56 { display: block; }", ".framer-Gmgo7 .framer-1satbo { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 14px 24px 14px 24px; position: relative; text-decoration: none; width: min-content; }", ".framer-Gmgo7 .framer-11lxfvo { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-Gmgo7 .framer-1bxyp62 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Gmgo7 .framer-1satbo, .framer-Gmgo7 .framer-11lxfvo { gap: 0px; } .framer-Gmgo7 .framer-1satbo > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Gmgo7 .framer-1satbo > :first-child { margin-left: 0px; } .framer-Gmgo7 .framer-1satbo > :last-child { margin-right: 0px; } .framer-Gmgo7 .framer-11lxfvo > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-Gmgo7 .framer-11lxfvo > :first-child { margin-top: 0px; } .framer-Gmgo7 .framer-11lxfvo > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 154
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"eBwpRTt01":"buttonLink"}
 * @framerImmutableVariables true
 */
const FramerSXtVKQ7cm: React.ComponentType<Props> = withCSS(Component, css, "framer-Gmgo7") as typeof Component;
export default FramerSXtVKQ7cm;

FramerSXtVKQ7cm.displayName = "Button - Get Template";

FramerSXtVKQ7cm.defaultProps = {height: 42, width: 154};

addPropertyControls(FramerSXtVKQ7cm, {eBwpRTt01: {title: "Button Link", type: ControlType.Link}} as any)

addFonts(FramerSXtVKQ7cm, [{family: "TASA Orbiter Deck Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/SXtVKQ7cm:default", url: "https://framerusercontent.com/assets/JZSKnEmpG2T4faxhadmAVU2k1A.otf"}, url: "https://framerusercontent.com/assets/JZSKnEmpG2T4faxhadmAVU2k1A.otf"}])